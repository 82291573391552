import React, { useState } from 'react'
import { graphql } from 'gatsby'
// import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import LayoutContainer from '../components/layout-container'
import Layout from '../containers/layout'
import FeaturedRelease from '../components/featured-release'

export const query = graphql`
  query IndexPageQueryES {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }

    home: allSanityHomepage(limit: 1) {
      edges {
        node {
          excerpt {
            es
            en
            _type
          }
          title {
            es
            en
            _type
          }
          mainImage {
            alt
            asset {
              _id
            }
          }
          ctaHero {
            _type
            kind
            title {
              en
              es
              _type
            }
            route {
              es
              en
              _type
            }
            link {
              es
              _type
              en
            }
          }
        }
      }
    }
  }
`

const IndexPage = props => {
  const { data, errors } = props
  const [language, setLanguage] = useState('es')

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const home = (data || {}).home.edges[0].node

  // const postNodes = (data || {}).posts
  //   ? mapEdgesToNodes(data.posts).filter(filterOutDocsWithoutSlugs)
  //   : []
  // const serviceNodes = (data || {}).services
  // mapEdgesToNodes(data.services)
  // console.log(serviceNodes)

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <LayoutContainer locale="es">
      <SEO title={site.title} description={site.description} keywords={site.keywords} lang="es" />
      <Container>
        <h1 hidden>Bienvenido a {site.title}</h1>
        {home && (
          <section>
            <FeaturedRelease
              title={home.title}
              excerpt={home.excerpt}
              cta={home.ctaHero}
              image={home.mainImage}
              locale="es"
            />
          </section>
        )}
        <section>
          <h2>Gira</h2>
          <p>Proximamente...</p>
        </section>
        <section>
          <h2>Noticias</h2>
          <p>Proximamente...</p>
        </section>
      </Container>
    </LayoutContainer>
  )
}

export default IndexPage
